@if (fromMenu) {
  <ng-container [ngTemplateOutlet]="menuCard" />
}

<!-- Menu card without animation -->
<ng-template #menuCard>
  @if (isVisible) {
    <mat-card
      class="mat-elevation-z0 menu-notification"
      [ngClass]="{
        'push-type cursor-pointer': notification.type === alertType.Push
      }"
      (click)="goToContext()"
    >
      <ng-container [ngTemplateOutlet]="notificationCardContent" />
    </mat-card>
  }
</ng-template>

<!-- Shared notification structure -->
<ng-template #notificationCardContent>
  <ng-container [ngTemplateOutlet]="stationNotification" />

  <!-- Dismiss button (toast) -->
  @if (!fromMenu) {
    <button mat-icon-button type="button" (click)="dismiss()">
      <i class="fas fa-times"></i>
    </button>
  }
</ng-template>

<!-- Other types notification.-->
<ng-template #otherNotifications>
  <div class="flex items-start">
    <!-- Profile photo / icon -->
    <app-user-avatar
      [firstName]="notification.user.firstName"
      [lastName]="notification.user.lastName"
      [profileImageRithmId]="notification.user.profileImageRithmId"
      [size]="'30'"
    />

    <!-- Notification content -->
    <div class="notification-content px-3">
      <p class="text-xs font-semibold">
        <span
          >{{ notification.user.firstName }}
          {{ notification.user.lastName }}</span
        >
        <span class="font-normal">
          {{ notification.note }}
        </span>
        <span class="text-secondary-500 font-normal">
          {{ time | utcDateFormat: dateFormatSetting : false : true }}</span
        >
      </p>
    </div>
    <ng-container [ngTemplateOutlet]="badgeUnread" />
  </div>
</ng-template>

<!-- Station's notification. -->
<ng-template #stationNotification>
  <div class="flex space-x-4">
    <i class="fak fa-station text-gray-500"></i>
    <div class="flex flex-col space-y-2 w-10/12">
      <section class="card-title flex space-x-2">
        <p class="font-semibold text-xs truncate text-primary-500">
          {{
            notification.stationName ||
              'Untitled ' + termsGeneric.Station.Single
          }}
        </p>
        <span class="text-primary-200 font-normal self-end text-xs truncate">
          {{
            notification.createdDateUTC
              | utcDateFormat: dateFormatSetting : false : true
          }}</span
        >
      </section>
      <section class="card-subtitle flex space-x-2">
        <i class="fal fa-box text-accent-500 text-xs"></i>
        <p class="text-xs text-primary-200 line-clamp-[2]">
          {{
            notification.containerName ||
              'Untitled ' + termsGeneric.Container.Single
          }}
        </p>
      </section>
      <section class="card-message bg-secondary-50 rounded-md p-2">
        <p class="text-xs text-primary-400 line-clamp-[2]">
          {{ notification.note }}
        </p>
      </section>
    </div>
    <ng-container [ngTemplateOutlet]="badgeUnread" />
  </div>
</ng-template>

<!-- Read badge (from menu) -->
<ng-template #badgeUnread>
  @if (!notification.hasRead && !justAlert) {
    <div class="read"></div>
  }
</ng-template>
