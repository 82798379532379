import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  FormControl,
  FormControlDirective,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import {
  ActionType,
  AnswerAsArray,
  ColumnsContainerInfo,
  FieldDataType,
  FieldsFromBucket,
  FieldToUpdate,
  FieldToUpdateElement,
  PowerAction,
  Question,
  QuestionAnswer,
  QuestionFieldType,
  StationBucketQuestion,
  StationRosterMember,
  UpdatedFieldNoQuestions,
  UserInfoBasicData,
} from 'src/models';
import { TextFieldComponent } from 'src/app/shared/fields/text-field/text-field.component';
import { FileFieldComponent } from 'src/app/shared/fields/file-field/file-field.component';
import { NumberFieldComponent } from 'src/app/shared/fields/number-field/number-field.component';
import { SelectFieldComponent } from 'src/app/shared/fields/select-field/select-field.component';
import _ from 'lodash';
import { ErrorService } from 'src/app/core/error.service';
import { StationService } from 'src/app/core/station.service';
import { first, forkJoin, Subject, takeUntil } from 'rxjs';
import { DateFieldComponent } from 'src/app/shared/fields/date-field/date-field.component';
import { ContainerService } from 'src/app/core/container.service';
import { DocumentFieldValidation } from 'src/helpers/document-field-validation';
import { v4 as uuidv4 } from 'uuid';
import {
  ActionHelper,
  ConditionHelper,
  STATES,
  TermsGeneric,
} from 'src/helpers';
import { UserService } from 'src/app/core/user.service';
import { PopupService } from 'src/app/core/popup.service';
import { MatDividerModule } from '@angular/material/divider';
import { UserAvatarComponent } from 'src/app/shared/user-avatar/user-avatar.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxSearchComponent } from 'src/app/shared/ngx-search/ngx-search.component';

/**
 * No values for Possible Answers.
 */
const NO_VALUE = {
  rithmId: '',
  text: '--no value--',
  order: 0,
  default: false,
};

/**
 * Component to represent update field action.
 *
 */
@Component({
  selector: 'app-update-field-action',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    LoadingIndicatorComponent,
    TextFieldComponent,
    DateFieldComponent,
    FileFieldComponent,
    NumberFieldComponent,
    SelectFieldComponent,
    UserAvatarComponent,
    NgxMatSelectSearchModule,
    NgxSearchComponent,
  ],
  templateUrl: './update-field-action.component.html',
  styleUrls: ['./update-field-action.component.scss'],
})
export class UpdateFieldActionComponent implements OnInit, OnDestroy {
  /** Contains the action of the power that will be edited. */
  private _actionToUpdate: PowerAction = {
    order: 1,
    rithmId: uuidv4(),
    type: ActionType.AddRelationship,
    target: '',
    data: '',
    resultMapping: '',
    header: '',
  };

  /** First field by default. */
  private firstSelectedField: FieldToUpdateElement = {
    questionType: QuestionFieldType.ShortText,
    type: 'field',
    value: '',
    text: '',
    settings: null,
  };

  /**Helper to condition question filtering. */
  conditionHelper = ConditionHelper;

  /** Observable for when the component is destroyed. */
  destroyed$ = new Subject<void>();

  /** The form group directive to reset form state. */
  @ViewChild(FormControlDirective) formControlDirective!: FormControlDirective;

  /** The component text-field to be updated. */
  @ViewChild('textField', { static: false })
  textField!: TextFieldComponent;

  /** The component number-field to be updated. */
  @ViewChild('numberField', { static: false })
  numberField!: NumberFieldComponent;

  /** The component date-field to be updated. */
  @ViewChild('dateField', { static: false })
  dateField!: DateFieldComponent;

  /** The component date-field to be updated. */
  @ViewChild('selectField', { static: false })
  selectField!: SelectFieldComponent;

  /** Contains the action of the power that will be edited. */
  @Input() actionToUpdate: PowerAction | null = null;

  /** The station id as an optional parameter. */
  @Input() stationRithmId = '';

  /** Whether the element is rendered from station or documents (true by default). */
  @Input() isStation = true;

  /** Bucket questions. */
  @Input({ required: true }) bucketQuestions: Question[] = [];

  /** Show or not custom fields option. */
  @Input() showFlowedBy = false;

  /** Feature flag order of operations. */
  @Input() orderOfOperations = false;

  /** Whether you are saving the current action . */
  @Input() savingAction = false;

  /** Confirms whether the action is being edited. */
  @Input() editingAction = false;

  /** Whether you are deleting the current action in parent. */
  @Input() deletingAction = false;

  /** Emit the current action to delete it. */
  @Output() actionToRemove = new EventEmitter<PowerAction>();

  /** Feature flag to show the rules for Number field in conditions. */
  @Input() showNumberFieldRules = false;

  /** Emit the new action to add. */
  @Output() containerPowerEmitter = new EventEmitter<PowerAction>();

  /** Emit Cancel the new action to add. */
  @Output() cancelForm = new EventEmitter<boolean>();

  /** System-wide generic terms. */
  termsGeneric = TermsGeneric;

  /** Default object for bucket field selected. */
  secondSelectedField: FieldToUpdateElement = {
    questionType: QuestionFieldType.ShortText,
    type: 'field',
    value: '',
    text: '',
    settings: null,
  };

  /** The field type. */
  fieldTypes = QuestionFieldType;

  /** Helper class for field validation. */
  fieldValidation = new DocumentFieldValidation();

  /** Current station questions deployed in update field. */
  selectedFieldToUpdate: FieldsFromBucket = {
    rithmId: '',
    prompt: '',
    questionType: QuestionFieldType.ShortText,
    possibleAnswers: [],
    children: [],
  };

  /** Source Question. */
  sourceQuestion: FieldsFromBucket | Question | null = null;

  /** An array of a mix of BucketQuestions and PreviousFields. */
  fieldsFromBucket: FieldsFromBucket[] = [];

  /** Statics field for select field  per elements no questions. */
  staticFields: UpdatedFieldNoQuestions[] = [
    {
      rithmId: ColumnsContainerInfo.AssignedUser,
      name: 'assigned to',
    },
  ];

  /** Custom fields elements per elements no questions. */
  customFields: UpdatedFieldNoQuestions[] = [
    {
      rithmId: ColumnsContainerInfo.FlowedBy,
      name: 'flowed by',
    },
    {
      rithmId: ColumnsContainerInfo.CreatedBy,
      name: 'created by',
    },
  ];

  /** Statics field for select none field. */
  noValueFields: UpdatedFieldNoQuestions[] = [
    {
      rithmId: 'no-value-id',
      name: '--no value--',
    },
  ];

  /** Statics field for select unassigned value. */
  unassignField: UpdatedFieldNoQuestions[] = [
    {
      rithmId: 'unassigned',
      name: 'unassigned',
    },
  ];

  /** Station member roster list. */
  stationRosterMember: StationRosterMember[] = [];

  /** Updated action information. */
  actionData: FieldToUpdate | null = null;

  /** Prompt of the First selected field. */
  selectedParentPrompt = '';

  /** Prompt of the child selected. */
  selectedChildPrompt = '';

  /** Notifies when the first field has changed. */
  changeFirstField = false;

  /** Prompt of the child selected. */
  selectedFieldType!: QuestionFieldType;

  /** Prompt of the child selected. */
  selectedChildType!: QuestionFieldType;

  /** Prompt of the First selected field. */
  selectedValueParentPrompt = '';

  /** Prompt of the child selectedValue. */
  selectedValueChildPrompt = '';

  /** Prompt of the child selectedValue. */
  selectedValueChildType!: QuestionFieldType;

  /** The field to update the filtered data in the select. */
  fieldToUpdateDataFiltered!: FieldDataType;

  /** The value to update the filtered data in the select. */
  valueToUpdateFieldDataFiltered!: FieldDataType;

  /** The custom value to update the data filtered in the select. */
  updateCustomValuedDataFiltered!: FieldDataType;

  /** Copy of the first selected question to update field. */
  questionCopy: Question = {
    rithmId: uuidv4(),
    prompt: 'Custom Value',
    questionType: QuestionFieldType.ShortText,
    isReadOnly: false,
    isRequired: false,
    isPrivate: false,
    possibleAnswers: [],
    children: [],
    answer: {
      questionRithmId: '',
      referAttribute: '',
      value: '',
      asString: '',
      asArray: [],
      asInt: 0,
      asJson: undefined,
    },
  };

  /** Display field type as field selected. */
  isCustomValues = true;

  /** Show fields section. */
  showFieldsSection = false;

  /** Show static fields section with roster members. */
  showOptionsUpdateTo = false;

  /** The form to update field to the template.*/
  updateFieldForm = new FormGroup({
    fieldToUpdate: new FormControl<string>('', {
      validators: [Validators.required],
    }),
    valueToUpdateField: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  /** Previous questions Loading Indicator. */
  bunchOfQuestionsLoading = false;

  /** Is user loading info. */
  isLoadingUser = false;

  /** Data user. */
  dataUser: UserInfoBasicData | undefined;

  /** Is first load to edit. */
  firstLoadToEdit = true;

  constructor(
    private stationService: StationService,
    private containerService: ContainerService,
    private errorService: ErrorService,
    private userService: UserService,
    private popupService: PopupService,
  ) {}

  /** Subscribe to containerAnswer$. */
  private subscribeContainerAnswer$(): void {
    this.containerService.containerAnswer$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((answer) => {
        this.secondSelectedField.questionType =
          this.firstSelectedField.questionType;
        // The answer label is the value to display in the saved action.
        const answerLabel = (answer.label || answer.value).toString();
        this.secondSelectedField.text =
          answer.type === QuestionFieldType.UserSelect && !answerLabel.length
            ? 'User unassigned'
            : answerLabel;

        this.secondSelectedField.type = this.checkFieldType(
          this.selectedFieldToUpdate.questionType,
        );
        // The answer value in the saved action.
        const answerValue =
          answer.type === QuestionFieldType.UserSelect && !answer.value
            ? '--no value--'
            : answer.value;
        this.secondSelectedField.value = answerValue.toString();
        this.updateFieldForm.controls.valueToUpdateField.setValue(answerValue);
      });
  }

  /**
   * Return the array without duplicated values and filtered by question types.
   * @param questionTypesExcluded Question types to exclude.
   * @returns Array filtered.
   */
  getFieldsFiltered(
    questionTypesExcluded?: QuestionFieldType[],
  ): FieldsFromBucket[] {
    return _.uniqBy(
      this.fieldsFromBucket.filter(
        (field) =>
          !ActionHelper.restrictedQuestionTypes.includes(field.questionType) &&
          (questionTypesExcluded
            ? !questionTypesExcluded.includes(field.questionType)
            : true),
      ),
      'rithmId',
    );
  }

  /**
   * Return the array without duplicated values.
   * @returns Array.
   */
  get purgedFieldsFromBucket(): FieldsFromBucket[] {
    return this.getFieldsFiltered([
      QuestionFieldType.Function,
      QuestionFieldType.CreatedOn,
      QuestionFieldType.PresentTime,
      QuestionFieldType.CreatedBy,
      QuestionFieldType.TimeInStation,
      QuestionFieldType.ContainerId,
      QuestionFieldType.StationId,
      QuestionFieldType.SelfAssign,
      QuestionFieldType.ParentStationRithmId,
      QuestionFieldType.ParentContainerRithmId,
    ]);
  }

  /**
   * Method to be able to identify if the question to be edited is of type userSelect.
   * @returns True or false.
   */
  get isEditingUserSelect(): boolean {
    let dataToUpdate = undefined;
    if (this.actionToUpdate) {
      dataToUpdate = JSON.parse(this.actionToUpdate.data) as FieldToUpdate;

      return (
        this.selectedFieldToUpdate.questionType ===
          dataToUpdate.target.questionType &&
        !this.firstLoadToEdit &&
        this.selectedFieldType === QuestionFieldType.UserSelect
      );
    } else {
      return false;
    }
  }

  /**
   * Returns a mixed collection from bucketQuestions and previousQuestions.
   * @returns Fields from bucket array.
   */
  get filteredFromBucket(): FieldsFromBucket[] {
    let filteredArray: FieldsFromBucket[] = [];
    const filteredFieldsFromBucket = ActionHelper.restrictTypesSecondSelect(
      this.fieldsFromBucket,
    );

    const isDateDurationField = [
      QuestionFieldType.Date,
      QuestionFieldType.Duration,
    ].includes(this.selectedFieldType);

    const isNumericField =
      [QuestionFieldType.Number, QuestionFieldType.Currency].includes(
        this.selectedFieldType,
      ) && this.showNumberFieldRules;

    if (isDateDurationField || isNumericField) {
      filteredArray = ActionHelper.getAvailableFieldsUpdateActions(
        this.selectedFieldType,
        filteredFieldsFromBucket.filter(
          (question) => question.rithmId !== this.selectedFieldToUpdate.rithmId,
        ),
      );
    } else {
      filteredArray = filteredFieldsFromBucket.filter((q) => {
        if (q.rithmId === this.selectedFieldToUpdate.rithmId) {
          return false;
        }

        /** Check if the selected field is address or shortText type. */
        if (this.isAddressRelatedType(this.selectedFieldType)) {
          /** Returns questions related to short field and address children. */
          return this.filterByAddressRelatedTypes(q);
        }
        /** Check if the selected field is of type date or presentTime. */
        if (this.selectedFieldType === QuestionFieldType.PresentTime) {
          return this.filterByDateOrPresentTime(q);
        }
        /** Returns the same type of question in bucket questions as the one in the selected field. */
        return q.questionType === this.selectedFieldToUpdate.questionType;
      });
    }

    /** Check if the selected field is of type text. */
    if (
      [QuestionFieldType.ShortText, QuestionFieldType.LongText].includes(
        this.selectedFieldType,
      )
    ) {
      return ActionHelper.getAvailableFieldsUpdateActions(
        this.selectedFieldType,
        filteredFieldsFromBucket,
      );
    } else if (QuestionFieldType.ContainerName === this.selectedFieldType) {
      return ActionHelper.availableFields(
        this.selectedFieldType,
        filteredFieldsFromBucket,
        ActionType.UpdateField,
      );
    }

    const uniqueArray = _.uniqBy(filteredArray, 'rithmId');
    return uniqueArray;
  }

  /**
   * Contains the question types related to address and container name.
   * @param questionType Question Type.
   * @returns True or false.
   */
  private isAddressRelatedType(questionType: QuestionFieldType): boolean {
    return [
      QuestionFieldType.AddressLine,
      QuestionFieldType.ContainerName,
    ].includes(questionType);
  }

  /**
   * Returns the children of type address or container name as the case may be.
   * @param q Selected question.
   * @returns True or false.
   */
  private filterByAddressRelatedTypes(q: FieldsFromBucket): boolean {
    if (
      this.selectedChildType === QuestionFieldType.City ||
      this.selectedChildType === QuestionFieldType.ShortText
    ) {
      return this.filterByCityOrShortText(q);
    }
    if (this.selectedChildType === QuestionFieldType.ContainerName) {
      return this.isContainerNameUpdatableField(q.questionType);
    }
    return (
      q.questionType !== QuestionFieldType.PresentTime &&
      q.questionType === QuestionFieldType.AddressLine &&
      !q.children.find((c) => c.rithmId === this.questionCopy.rithmId)
    );
  }

  /**
   * Check if it is a child of Address or just ShortText to return the corresponding types.
   * @param q Selected question.
   * @returns True or false.
   */
  private filterByCityOrShortText(q: FieldsFromBucket): boolean {
    const shortTextRelatedTypes = [
      QuestionFieldType.ParentStationRithmId,
      QuestionFieldType.ParentContainerRithmId,
      QuestionFieldType.PresentTime,
    ];
    return (
      [
        QuestionFieldType.AddressLine,
        QuestionFieldType.City,
        QuestionFieldType.ShortText,
      ].includes(q.questionType) ||
      (this.selectedChildType === QuestionFieldType.ShortText &&
        shortTextRelatedTypes.includes(q.questionType))
    );
  }

  /**
   * Check and return questions of date type and related types.
   * @param q Selected question.
   * @returns True or false.
   */
  private filterByDateOrPresentTime(q: FieldsFromBucket): boolean {
    const dateRelatedTypes = [
      QuestionFieldType.PresentTime,
      QuestionFieldType.Date,
    ];
    return (
      dateRelatedTypes.includes(q.questionType) ||
      (this.selectedChildType === QuestionFieldType.PresentTime &&
        q.questionType === QuestionFieldType.ShortText)
    );
  }

  /**
   * Life cycle init the component.
   */
  ngOnInit(): void {
    if (this.actionToUpdate) {
      const dataToUpdate = JSON.parse(this.actionToUpdate.data);
      if (dataToUpdate.target.questionType === QuestionFieldType.UserSelect) {
        if (!dataToUpdate.source.text.includes('unassigned')) {
          this.getUserInfoBasic(dataToUpdate.source.value || '');
        }
      }
      if (dataToUpdate.target.questionType === QuestionFieldType.AssignedUser) {
        let sourceValue = dataToUpdate.source.value;

        sourceValue = Object.values(QuestionFieldType).includes(
          dataToUpdate.source.questionType,
        )
          ? dataToUpdate.source.questionType
          : 'unassigned';

        dataToUpdate.source.value.includes('field') &&
          (sourceValue = dataToUpdate.source.value.split(':')[1]);
        this.setUpdateFieldAssignedTo(sourceValue);
      }
    }

    this.subscribeContainerAnswer$();
    this.getPreviousAndBucketQuestions();
    this.getStationUsersRoster();
    this.setCustomFields();
  }

  /**
   * Set custom fields from buckets questions.
   */
  private setCustomFields(): void {
    if (this.bucketQuestions) {
      const questions: UpdatedFieldNoQuestions[] = this.bucketQuestions
        .filter(
          (question) => question.questionType === QuestionFieldType.UserSelect,
        )
        .map((question) => {
          return {
            rithmId: question.rithmId,
            name: question.prompt,
          };
        });
      this.customFields = this.customFields.concat(questions);

      if (!this.showFlowedBy) {
        const indexFlowedOption = this.customFields.findIndex(
          ({ rithmId: key }) => key === ColumnsContainerInfo.FlowedBy,
        );
        this.customFields.splice(indexFlowedOption, 1);
      }
    }
  }

  /**
   * Get Users Roster for a given Station.
   */
  private getStationUsersRoster(): void {
    this.stationService
      .getStationWorkerRoster(this.stationRithmId, this.orderOfOperations)
      .pipe(first())
      .subscribe({
        next: (members) => {
          this.stationRosterMember = members;
        },
      });
  }

  /**
   * Request for all the bucket and Previous Questions.
   */
  private getPreviousAndBucketQuestions(): void {
    this.bunchOfQuestionsLoading = true;
    const request = [
      this.stationService.getStationBucketQuestions(this.stationRithmId),
    ];
    forkJoin(request)
      .pipe(first())
      .subscribe({
        next: ([BQuestion]) => {
          const _bQuestion: StationBucketQuestion[] = (
            BQuestion as StationBucketQuestion[]
          ).filter(
            (e) =>
              e.prompt !== 'Address Line 1' &&
              e.prompt !== 'Address Line 2' &&
              e.questionType !== QuestionFieldType.City &&
              e.questionType !== QuestionFieldType.State &&
              e.questionType !== QuestionFieldType.Zip,
          );
          this.extractFieldsFromArray(_bQuestion);
          this.bunchOfQuestionsLoading = false;
          setTimeout(() => {
            this.setInformationToEdit();
          }, 1000);
        },
        error: (error: unknown) => {
          this.bunchOfQuestionsLoading = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Extract an array of bucketQuestions or previousQuestions for fieldsFromBucket.
   * @param bucket The array to extract fields.
   */
  private extractFieldsFromArray(bucket: StationBucketQuestion[]): void {
    // The questions enabled only for the update field options.
    const questionsBucket =
      ActionHelper.removeContainerInfoBucketDuplicate(bucket);
    this.fieldsFromBucket = questionsBucket.map((bucketQuestion) => {
      const question = bucketQuestion as unknown as Question;
      return <Question>{
        rithmId: bucketQuestion.rithmId,
        prompt: bucketQuestion.prompt,
        questionType: bucketQuestion.questionType as QuestionFieldType,
        possibleAnswers: question.possibleAnswers || [],
        children: question.children,
        settings: question?.settings,
      };
    });
  }

  /**
   * Set values to update field for assigned to.
   * @param value Value previous saved.
   */
  setUpdateFieldAssignedTo(value: string): void {
    this.updateFieldForm.controls.fieldToUpdate.setValue(
      ColumnsContainerInfo.AssignedUser,
    );
    this.updateFieldForm.controls.valueToUpdateField.setValue(value);
    this.selectedParentPrompt = 'assigned to';
    this.showOptionsUpdateTo = true;
  }

  /**
   * Returns a mixed collection from bucketQuestions and previousQuestions.
   * @param parent The address parent selected.
   * @returns Fields from bucket array.
   */
  filteredAddressChildren(parent: FieldsFromBucket): Question[] {
    let arrayFiltered: Question[] = [];

    if (
      this.selectedChildType !== QuestionFieldType.City &&
      this.selectedChildType !== QuestionFieldType.ShortText &&
      this.selectedChildType !== QuestionFieldType.ContainerName
    ) {
      arrayFiltered = parent.children.filter(
        (q) => q.questionType === this.selectedValueChildType,
      );
    } else if (this.selectedChildType === QuestionFieldType.ContainerName) {
      arrayFiltered = parent.children.filter(
        (q) =>
          q.questionType === QuestionFieldType.City ||
          q.questionType === QuestionFieldType.Zip ||
          q.questionType === QuestionFieldType.ShortText,
      );
    } else {
      arrayFiltered = parent.children.filter(
        (q) =>
          q.questionType === QuestionFieldType.City ||
          q.questionType === QuestionFieldType.ShortText,
      );
    }
    return arrayFiltered.filter((q) => q.rithmId !== this.questionCopy.rithmId);
  }

  /**
   * This method will set the information to edit an already created action.
   */
  private setInformationToEdit(): void {
    if (this.actionToUpdate) {
      const dataToUpdate = JSON.parse(
        this.actionToUpdate.data,
      ) as FieldToUpdate;

      const isAddressField = this.isAddressChild(
        dataToUpdate.target.text as string,
      );
      this.isCustomValues = dataToUpdate.source.type !== 'field';
      if (isAddressField) {
        //If the first field comes from an Address field
        this.setAddressFieldToUpdate(dataToUpdate);
      } else {
        this.setRegularFieldToUpdate(dataToUpdate);
      }
    }
    this.firstLoadToEdit = false;
  }

  /**
   * Set the information to be updated when it comes from an address field.
   * @param dataToUpdate Variable to pull information.
   */
  private setAddressFieldToUpdate(dataToUpdate: FieldToUpdate): void {
    let isRegularValue = false; //If the second field is not an address children.
    let _fieldToUpdate: FieldsFromBucket | Question | undefined = undefined;
    let _valueToCopy: FieldsFromBucket | Question | undefined = undefined;
    //First we grab the address parent of the first field.
    const addressParentA = this.purgedFieldsFromBucket.find(
      (q) =>
        q.questionType === QuestionFieldType.AddressLine &&
        q.children.find((c) => c.rithmId === dataToUpdate.target.value),
    );
    _fieldToUpdate = addressParentA?.children.find(
      (q) => q.rithmId === dataToUpdate.target.value,
    );

    if (_fieldToUpdate && addressParentA) {
      this.selectedFieldToUpdate = _fieldToUpdate;
      this.selectingFieldToUpdate(
        addressParentA.rithmId,
        this.isCustomValues,
        _fieldToUpdate as Question,
      );
      this.updateFieldForm.controls.fieldToUpdate.setValue(
        _fieldToUpdate.rithmId,
      );
    }

    //Then we grab the address parent of the first field.
    const addressParentB = this.purgedFieldsFromBucket.find(
      (q) =>
        q.questionType === QuestionFieldType.AddressLine &&
        q.children.find((c) => c.rithmId === dataToUpdate.source.value),
    );

    _valueToCopy = addressParentB?.children.find(
      (q) => q.rithmId === dataToUpdate.source.value,
    );

    if (!_valueToCopy) {
      _valueToCopy = this.purgedFieldsFromBucket.find(
        (q) => q.rithmId === dataToUpdate.source.value,
      );
      isRegularValue = _valueToCopy ? true : false;
    }
    setTimeout(() => {
      if (_valueToCopy) {
        if (!this.isCustomValues) {
          if (isRegularValue) {
            this.sourceQuestion = _valueToCopy;
            this.selectingFieldValue(_valueToCopy.rithmId);
          } else {
            this.sourceQuestion = _valueToCopy;
            this.selectedValueChildType = _valueToCopy.questionType;
            this.selectingFieldValue(
              addressParentB?.rithmId as string,
              _valueToCopy as Question,
            );
          }
        }
      } else {
        //In case the copy value does not exist and is different from a field, the second selection field must be completed.
        if (dataToUpdate.source.type !== 'field') {
          this.secondSelectedField = {
            questionType: dataToUpdate.target.questionType,
            type: this.checkFieldType(dataToUpdate.target.questionType),
            value: dataToUpdate.source.value,
            text: dataToUpdate.source.text,
            settings: dataToUpdate.source.settings,
          };
        } else {
          this.updateFieldForm.controls.valueToUpdateField.setValue(
            '--no value--',
          );
          this.selectingFieldValue('');
        }
      }
    }, 500);
  }

  /**
   * Set the information to be updated when it comes from a regular field.
   * @param dataToUpdate Variable to pull information.
   */
  private setRegularFieldToUpdate(dataToUpdate: FieldToUpdate): void {
    let isChildrenValue = false; //If the second field is an address children.
    let addressParentId = ''; //if so then we'll get the parent Id.
    let _fieldToUpdate: FieldsFromBucket | Question | undefined = undefined;
    let _valueToCopy: FieldsFromBucket | Question | undefined = undefined;

    //We get the first field information from fields outside addressFields.
    _fieldToUpdate = this.purgedFieldsFromBucket.find(
      (q) => q.rithmId === dataToUpdate.target.value,
    );

    //We'll try to get the second field information outside addressFields.
    _valueToCopy = this.getFieldsFiltered().find(
      (q) => q.rithmId === dataToUpdate.source.value,
    );

    //If field is ShortText and there are not value then probably is inside an AddressChildren array.
    if (
      [QuestionFieldType.ShortText, QuestionFieldType.ContainerName].includes(
        dataToUpdate.target.questionType,
      ) &&
      !_valueToCopy
    ) {
      const addressParentB = this.purgedFieldsFromBucket.find(
        (q) =>
          q.questionType === QuestionFieldType.AddressLine &&
          q.children.find((c) => c.rithmId === dataToUpdate.source.value),
      );

      addressParentId = addressParentB?.rithmId || '';

      _valueToCopy = addressParentB?.children.find(
        (child) => child.rithmId === dataToUpdate.source.value,
      );

      isChildrenValue = _valueToCopy ? true : false;
    }

    if (_fieldToUpdate) {
      this.selectedFieldToUpdate = _fieldToUpdate;
      this.selectingFieldToUpdate(_fieldToUpdate.rithmId, this.isCustomValues);
      this.updateFieldForm.controls.fieldToUpdate.setValue(
        _fieldToUpdate.rithmId,
      );
    }
    setTimeout(() => {
      if (_valueToCopy) {
        if (!this.isCustomValues) {
          if (isChildrenValue) {
            this.selectedValueChildType = _valueToCopy.questionType;
            this.selectingFieldValue(addressParentId, _valueToCopy as Question);
          } else {
            this.sourceQuestion = _valueToCopy;
            this.selectingFieldValue(_valueToCopy.rithmId);
          }
        }
      } else {
        //In case the copy value does not exist and is different from a field, the second selection field must be completed.
        if (dataToUpdate.source.type !== 'field') {
          this.secondSelectedField = {
            questionType: dataToUpdate.target.questionType,
            type: this.checkFieldType(dataToUpdate.target.questionType),
            value: dataToUpdate.source.value,
            text: dataToUpdate.source.text,
            settings: dataToUpdate.source.settings,
          };
        } else {
          this.updateFieldForm.controls.valueToUpdateField.setValue(
            '--no value--',
          );
          this.selectingFieldValue('');
        }
      }
    }, 500);
  }

  /**
   * Get the formatted question type.
   * @param type String to sentence.
   * @returns Formatted type.
   */
  titleCaseType(type: QuestionFieldType): string {
    return _.startCase(type);
  }

  /**
   * Add validations according to the type of field.
   */
  fieldValidationToUpdate(): void {
    const validators: ValidatorFn[] = [];
    if (
      this.selectedFieldToUpdate.questionType !== QuestionFieldType.UserSelect
    ) {
      validators.push(Validators.required);
    }
    if (this.isCustomValues) {
      switch (this.selectedFieldToUpdate.questionType) {
        case QuestionFieldType.Email:
          validators.push(Validators.email);
          break;
        case QuestionFieldType.URL:
          // eslint-disable-next-line no-case-declarations
          const url_regex = new RegExp(
            /^(ftp|http|https)(:\/\/)(www\.)?[a-zA-Z0-9@:%._+~#=-][^..]{1,256}\.?[a-zA-Z0-9()]{1,256}/,
          );
          validators.push(Validators.pattern(url_regex));
          validators.push(this.fieldValidation.urlValidation());
          break;
        case QuestionFieldType.Zip:
          validators.push(this.fieldValidation.zipValidation());
          break;
        case QuestionFieldType.Currency:
          validators.push(this.fieldValidation.currencyValidation());
          break;
        case QuestionFieldType.Phone:
          validators.push(this.fieldValidation.phoneValidation());
          break;
        case QuestionFieldType.Select:
        case QuestionFieldType.RadioList:
        case QuestionFieldType.State:
          this.questionCopy.possibleAnswers =
            this.questionCopy.possibleAnswers?.filter(
              (ans) => ans.text !== NO_VALUE.text,
            );
          this.questionCopy.possibleAnswers?.splice(0, 0, NO_VALUE);
          break;
        default:
          break;
      }
    }
    this.updateFieldForm.controls.valueToUpdateField.setValidators(validators);
    if (this.sourceQuestion) {
      this.updateFieldForm.controls.valueToUpdateField.setValue(
        this.sourceQuestion.rithmId,
      );
    }
  }

  /**
   * Applies the question type change to either custom values or previous field/cube questions.
   */
  switchFieldToUpdate(): void {
    this.sourceQuestion = null;
    this.showFieldsSection = true;
    this.updateFieldForm.controls.valueToUpdateField.reset();
    this.questionCopy.value = '';
    this.questionCopy.answer = {} as QuestionAnswer;
    this.selectedValueChildType = this.selectedChildType;
    this.selectedValueParentPrompt = '';
    this.selectedValueChildPrompt = '';
    setTimeout(() => {
      this.showFieldsSection = false;
    }, 500);
    this.isCustomValues = !this.isCustomValues;
    this.fieldValidationToUpdate();
  }

  /**
   * Reset component field when a field question type is selected.
   * @param qRithmId A question.
   * @param externalUpdate If the field is updated from other method.
   * @param child The child of the Address Field in case of.
   */
  selectingFieldToUpdate(
    qRithmId: string,
    externalUpdate = true,
    child: Question | null = null,
  ): void {
    const question = this.purgedFieldsFromBucket.find(
      (q) => q.rithmId === qRithmId,
    );
    const childQuestion = child;
    this.isCustomValues = externalUpdate;
    this.showFieldsSection = true;
    this.showOptionsUpdateTo = false;
    if (question) {
      //Display information when the Dropdown Change.
      this.selectedParentPrompt = question.prompt;
      this.selectedChildPrompt = childQuestion?.prompt || '';
      this.selectedFieldType = question.questionType;
      this.selectedChildType =
        childQuestion?.questionType || question.questionType;
      //Update forms in the second dropdown.
      this.updateFieldForm.controls.valueToUpdateField.setValue('');
      //Copy the information in questionCopyObject
      this.questionCopy.rithmId = childQuestion?.rithmId || question.rithmId;
      this.questionCopy.questionType = this.selectedChildType;
      this.questionCopy.possibleAnswers = childQuestion
        ? childQuestion.questionType === QuestionFieldType.State
          ? STATES
          : childQuestion.possibleAnswers
        : this.isSelectionField(question.questionType)
          ? question.possibleAnswers
          : [];
      this.questionCopy.settings = question.settings;
      if (this.dataUser) {
        const answer: QuestionAnswer = {
          questionRithmId: uuidv4(),
          referAttribute: '',
          asArray: [
            {
              value: '',
              isChecked: false,
            },
          ],
          asInt: 0,
          asDecimal: 0,
          asString: '',
          asDate: '',
          value: '',
          asJson: this.dataUser,
        };
        this.questionCopy.answer = answer;
      }

      this.questionCopy.children = !childQuestion ? question.children : [];
      //Set the information of the first selected field.
      this.firstSelectedField.value =
        childQuestion?.rithmId || question.rithmId;
      this.firstSelectedField.questionType = this.selectedChildType;
      this.firstSelectedField.text = childQuestion
        ? `${question.prompt} / ${childQuestion.prompt}`
        : question.prompt;

      if (this.actionToUpdate) {
        const dataToUpdate = JSON.parse(
          this.actionToUpdate.data,
        ) as FieldToUpdate;
        if (
          this.isSelectionField(this.questionCopy.questionType) &&
          this.questionCopy.answer
        ) {
          this.setPossibleAnswersAsChecked(
            this.questionCopy.questionType,
            dataToUpdate,
          );
        }

        if (
          this.isEditingUserSelect ||
          this.selectedFieldToUpdate.questionType ===
            dataToUpdate.target.questionType
        ) {
          this.updateFieldForm.controls.valueToUpdateField.setValue(
            dataToUpdate.source.value as string,
          );
          this.questionCopy.value = dataToUpdate.source.value as string;
        } else if (
          this.selectedFieldToUpdate.questionType !==
          dataToUpdate.target.questionType
        ) {
          this.updateFieldForm.controls.valueToUpdateField.setValue('');
          this.questionCopy.value = '';
          delete this.questionCopy.answer;
        }
      }
      if (this.selectedFieldType === QuestionFieldType.Duration) {
        this.isCustomValues = false;
      }
      this.optionSelected(this.questionCopy);
      this.restartChildComponents(question.questionType);
    }
  }

  /**
   * Select static field for select field.
   * @param key Element selected.
   */
  selectStaticField(key: UpdatedFieldNoQuestions): void {
    this.selectedParentPrompt = key.name;
    this.showOptionsUpdateTo = true;
  }

  /**
   * Set Possible Answers as selected.
   * @param type The type of the question.
   * @param data The text to be verify.
   */
  private setPossibleAnswersAsChecked(
    type: QuestionFieldType,
    data: FieldToUpdate,
  ): void {
    const answersSelected = (data.source.text as string)
      .replace(/~/g, ',')
      .split('|');
    const answersArray: AnswerAsArray[] = [];
    if (this.questionCopy.answer) {
      if (type === QuestionFieldType.State) {
        this.questionCopy.answer.asString = data.source.text || '';
        this.questionCopy.answer.value = data.source.text || '';
      } else {
        this.questionCopy.possibleAnswers?.forEach((answer) => {
          answersArray.push({
            value: answer.text,
            isChecked: false,
          });
        });
        answersArray.forEach((a) => {
          a.isChecked = answersSelected.find((as) => a.value === as)
            ? true
            : false;
        });
        this.questionCopy.answer.asArray = answersArray;
      }
    }
  }

  /**
   * When a field is selected instead of a custom value.
   * @param qRithmId Bucket/Previous question id.
   * @param child Child selected in Address field cases.
   */
  selectingFieldValue(qRithmId: string, child: Question | null = null): void {
    const question = this.getFieldsFiltered().find(
      (q) => q.rithmId === qRithmId,
    );
    const childQuestion = child;

    if (question) {
      //Display field value information when the Dropdown Change.
      this.selectedValueParentPrompt = question.prompt;
      this.selectedValueChildPrompt = childQuestion?.prompt || '';

      this.secondSelectedField.questionType =
        childQuestion?.questionType || question.questionType;
      this.secondSelectedField.text = childQuestion
        ? `${question.prompt} / ${childQuestion.prompt}`
        : question.prompt;
      this.secondSelectedField.value =
        childQuestion?.rithmId || question.rithmId;
      this.secondSelectedField.type = 'field';
    } else {
      //when question not found or none value was selected.
      this.selectedValueParentPrompt = '--no value--';
      this.selectedValueChildPrompt = '';

      this.secondSelectedField.questionType = QuestionFieldType.ShortText;
      this.secondSelectedField.text = 'empty';
      this.secondSelectedField.value = '--no value--';
      this.secondSelectedField.type = 'field';
    }
  }

  /**
   * Restart the proper ViewChild component.
   * @param type The type of the selected value to update.
   */
  private restartChildComponents(type: QuestionFieldType): void {
    setTimeout(() => {
      switch (type) {
        case QuestionFieldType.ShortText:
        case QuestionFieldType.LongText:
        case QuestionFieldType.Email:
        case QuestionFieldType.URL:
        case QuestionFieldType.AddressLine:
        case QuestionFieldType.File:
          this.textField?.ngOnInit();
          break;
        case QuestionFieldType.Number:
        case QuestionFieldType.Phone:
        case QuestionFieldType.Currency:
          this.numberField?.ngOnInit();
          break;
        case QuestionFieldType.Date:
        case QuestionFieldType.DateTime:
        case QuestionFieldType.PresentTime:
        case QuestionFieldType.TimeInStation:
        case QuestionFieldType.CreatedOn:
          this.dateField?.ngOnInit();
          break;
        case QuestionFieldType.Select:
        case QuestionFieldType.MultiSelect:
        case QuestionFieldType.RadioList:
        case QuestionFieldType.CheckList:
          this.selectField?.ngOnInit();
          break;
      }
      this.showFieldsSection = false;
    }, 500);
  }

  /**
   * Selected field for the updates.
   * @param field The field prompt selected.
   */
  optionSelected(field: Question): void {
    if (field.questionType === QuestionFieldType.UserSelect) {
      field.isRequired = false;
    } else {
      field.isRequired = true;
    }
    field.value = this.changeFirstField && field.value ? '' : field.value;
    this.selectedFieldToUpdate = field;
    this.fieldValidationToUpdate();
  }

  /**
   * Check the question type of question source.
   * @param type The current question type.
   * @returns The type of the question selected.
   */
  checkFieldType(type: QuestionFieldType): string {
    let checkedType = '';
    switch (type) {
      case QuestionFieldType.Number:
      case QuestionFieldType.Currency:
      case QuestionFieldType.Zip:
        checkedType = 'number';
        break;
      case QuestionFieldType.Date:
      case QuestionFieldType.PresentTime:
      case QuestionFieldType.TimeInStation:
      case QuestionFieldType.CreatedOn:
        checkedType = 'date';
        break;
      default:
        checkedType = 'string';
        break;
    }
    return checkedType;
  }

  /**
   * Saving action type changes.
   */
  public addUpdatedField(): void {
    if (this.secondSelectedField.value === '--no value--') {
      this.secondSelectedField.value = '';
    }
    const actionToUpdateField: PowerAction = this.actionToUpdate || {
      order: this._actionToUpdate ? this._actionToUpdate.order : 1,
      rithmId: uuidv4(),
      type: ActionType.UpdateField,
      target: this.selectedFieldToUpdate.rithmId,
      data: '',
      resultMapping: '',
      header: '',
    };

    let fieldData;

    if (this.showOptionsUpdateTo) {
      const valueToUpdateField =
        this.updateFieldForm.value.valueToUpdateField || '';
      const questionUserSelect = this.bucketQuestions.find(
        (q) => q.rithmId === valueToUpdateField,
      );

      const questionType = questionUserSelect
        ? QuestionFieldType.UserSelect
        : Object.values(QuestionFieldType).includes(
              valueToUpdateField as QuestionFieldType,
            )
          ? valueToUpdateField
          : '';

      fieldData = {
        target: {
          questionType: 'assignedUser',
          type: 'string',
          value: '',
          text: 'assigned to',
        },
        source: {
          questionType,
          type: 'string',
          value: '',
          text: '',
        },
      };

      if (questionType === QuestionFieldType.UserSelect && questionUserSelect) {
        fieldData.source.value = `field:${questionUserSelect.rithmId}`;
        fieldData.source.text = questionUserSelect.prompt;
      }

      const member = this.stationRosterMember.find(
        (e) => e.rithmId === valueToUpdateField,
      );

      if (member) {
        fieldData.source.questionType = QuestionFieldType.UserSelect;
        fieldData.source.value = member.rithmId;
        fieldData.source.text = member.firstName + ' ' + member.lastName;
      }
    }
    actionToUpdateField.data = JSON.stringify(
      this.showOptionsUpdateTo
        ? fieldData
        : {
            target: this.firstSelectedField,
            source: this.secondSelectedField,
          },
    );
    this.containerPowerEmitter.emit(actionToUpdateField);
  }

  /**
   * Whether is text field or not.
   * @param questionType Type of question.
   * @returns A boolean.
   */
  isTextField(questionType: QuestionFieldType): boolean {
    return [
      QuestionFieldType.ShortText,
      QuestionFieldType.LongText,
      QuestionFieldType.Email,
      QuestionFieldType.URL,
      QuestionFieldType.AddressLine,
      QuestionFieldType.File,
      QuestionFieldType.ContainerName,
      QuestionFieldType.StationName,
      QuestionFieldType.CustomId,
      QuestionFieldType.City,
      QuestionFieldType.CreatedBy,
    ].includes(questionType);
  }

  /**
   * Whether is number field or not.
   * @param questionType Type of question.
   * @returns A boolean.
   */
  isNumberField(questionType: QuestionFieldType): boolean {
    return [
      QuestionFieldType.Number,
      QuestionFieldType.Phone,
      QuestionFieldType.Currency,
      QuestionFieldType.Zip,
    ].includes(questionType);
  }

  /**
   * Whether is date field or not.
   * @param questionType Type of question.
   * @returns A boolean.
   */
  isDateField(questionType: QuestionFieldType): boolean {
    return [
      QuestionFieldType.Date,
      QuestionFieldType.DateTime,
      QuestionFieldType.PresentTime,
      QuestionFieldType.TimeInStation,
      QuestionFieldType.CreatedOn,
    ].includes(questionType);
  }

  /**
   * Whether is select field or not.
   * @param questionType Type of question.
   * @returns A boolean.
   */
  isSelectionField(questionType: QuestionFieldType): boolean {
    return [
      QuestionFieldType.Select,
      QuestionFieldType.MultiSelect,
      QuestionFieldType.RadioList,
      QuestionFieldType.CheckList,
      QuestionFieldType.State,
      QuestionFieldType.UserSelect,
    ].includes(questionType);
  }

  /**
   * Check whether a field is an address child.
   * @param text The text saved with the action.
   * @returns Boolean.
   */
  isAddressChild(text: string): boolean {
    return (
      text.includes('/ Address Line') ||
      text.includes('/ City') ||
      text.includes('/ State') ||
      text.includes('/ Zip')
    );
  }

  /**
   * Whether the container name updatable field or not.
   * @param questionType Type of question.
   * @returns A boolean.
   */
  isContainerNameUpdatableField(questionType: QuestionFieldType): boolean {
    return [
      QuestionFieldType.ContainerName,
      QuestionFieldType.AddressLine,
      QuestionFieldType.ShortText,
      QuestionFieldType.Date,
      QuestionFieldType.Number,
      QuestionFieldType.CustomId,
    ].includes(questionType);
  }

  /**
   * Cancel the process of creating a new action.
   */
  cancelAction(): void {
    this.cancelForm.emit();
  }

  /**
   * Method for obtain basic data for user specific.
   * @param userRithmId User rithmId.
   */
  private getUserInfoBasic(userRithmId: string): void {
    this.isLoadingUser = true;
    userRithmId &&
      this.userService
        .getUserInfoBasic(userRithmId)
        .pipe(first())
        .subscribe({
          next: (dataUser) => {
            this.dataUser = dataUser;
            this.isLoadingUser = false;
          },
          error: () => {
            this.isLoadingUser = false;
            this.popupService.notify(
              'Error Getting user info. Please cancel and try again',
              true,
            );
          },
        });
  }

  /**
   * Remove the action from the parent.
   */
  removeParentAction(): void {
    this.actionToUpdate && this.actionToRemove.emit(this.actionToUpdate);
  }

  /**
   * OnDestroy life cycle.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
