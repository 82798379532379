import { Component, EventEmitter, Input, Output } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { AlertType, Alert, AlertServiceType } from 'src/models';
import { DATE_FORMAT_SETTING, TermsGeneric } from 'src/helpers';
import { LocationModalComponent } from 'src/app/shared/location-modal/location-modal.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { UserAvatarComponent } from 'src/app/shared/user-avatar/user-avatar.component';
import { UtcDateFormatPipe } from 'src/helpers/pipes/utc-date-format/utc-date-format.pipe';

/**
 * Reusable component for notification cards.
 */
@Component({
  selector: 'app-notification-card[notification]',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    UserAvatarComponent,
    MatDialogModule,
    UtcDateFormatPipe,
  ],
  animations: [
    trigger('notificationTrigger', [
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'translateX(100%)',
        }),
        animate(
          '600ms',
          style({
            opacity: 1,
            transform: 'translateX(0)',
          }),
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate(
          '500ms',
          style({
            opacity: 0,
            transform: 'translateX(100%)',
          }),
        ),
      ]),
    ]),
  ],
})
export class NotificationCardComponent {
  /** Notification Type. */
  readonly alertType = AlertType;

  /** System-wide generic terms. */
  termsGeneric = TermsGeneric;

  /** Contain all alert service types. */
  alertServiceTypes = AlertServiceType;

  /** The event to dismiss a notification. */
  @Output() dismissEvent: EventEmitter<unknown> = new EventEmitter();

  /** Notification data. */
  @Input() notification!: Alert;

  /** When was the notification sent. */
  @Input() time!: string;

  /** Whether the notification is from the menu. */
  @Input() fromMenu = false;

  /** Contains whether it is alert only or notification list. */
  @Input() justAlert = false;

  /** Whether the notification is visible (used to trigger the animate out). */
  isVisible = true;

  /** Date format settings. */
  dateFormatSetting = DATE_FORMAT_SETTING;

  constructor(private dialog: MatDialog) {}

  /**
   * Dismisses this specific notification.
   */
  dismiss(): void {
    this.isVisible = false;
    this.dismissEvent.emit();
  }

  /**
   * Navigates the user to the specific context for the notification. For example,
   * getting tagged in a comment will bring them to that specific document/station/etc.
   */
  goToContext(): void {
    if (
      this.notification.containerRithmId.length &&
      this.notification.stationRithmId.length
    ) {
      if (this.notification.stationRithmId.length > 1) {
        this.dialog.open(LocationModalComponent, {
          minWidth: '350px',
          width: '450px',
          minHeight: '450px',
          data: {
            containerRithmId: this.notification.containerRithmId,
          },
        });
      } else {
        const url = `/${TermsGeneric.Container.Single.toLowerCase()}/${
          this.notification.containerRithmId
        }?containerId=${this.notification.containerRithmId}&stationId=${
          this.notification.stationRithmId
        }`;
        window.open(url, '_blank');
      }
    }
  }
}
